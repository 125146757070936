.data {
	margin: 0 -24px -24px;
	@include m {
		margin: 0; }
	&__head {
		padding: 12px 24px;
		background: $bg-lighter;
		border: 1px solid transparent;
		color: $gray;
		@include m {
			display: none; } }
	&__row {
		display: flex;
		align-items: center; }
	&__cell {
		padding: 0 8px;
		flex: 1;
		@include m {
			padding: 0 14px 0 0;
			flex: auto; }
		&:first-child {
			padding-left: 0; }
		&:last-child {
			padding-right: 24px;
			@include m {
				padding-right: 14px; } }
		&_xl {
			flex: 3;
			@include m {
				flex: 1 1 auto; } }
		&_lg {
			flex: 2;
			@include m {
				flex: 1 1 auto; } }
		&_md {
			flex: 1.5;
			@include m {
				flex: 1 1 auto; } }
		&_sm {
			flex: .75; }
		&_xs {
			flex: initial; }
		&_members,
		&_tags {
			min-width: 172px;
			flex: 1.3; }
		&_action {
			flex: 0 0 68px;
			@include m {
				flex: 0 0 50px; } }
		&_toggle {
			max-width: 72px;
			flex: 0 0 72px;
			@include m {
				max-width: 54px;
				flex: 0 0 54px; } }
		&_time {
			flex: 0 0 84px; } }
	&__filter {
		display: flex;
		align-items: center;
		justify-content: space-between;
		transition: color .2s;
		cursor: pointer;
		&:hover {
			color: $cl; } }
	&__body {
		padding: 24px;
		@include m {
			padding: 0; } }
	&__item {
		padding: 20px 0;
		border: 1px solid $border;
		border-radius: 12px;
		@include m {
			padding: 12px 0;
			background: white;
			border: none;
			border-radius: 8px; }
		&:not(:last-child) {
			margin-bottom: 8px;
			@include m {
				margin-bottom: 4px; } } }
	&__icon {
		color: $gray; }
	&__effect {
		margin-right: 20px; }
	&__main {
		display: flex;
		padding-left: 24px;
		align-items: center;
		@include m {
			padding-left: 14px; } }
	&__preview {
		display: flex;
		position: relative;
		width: 48px;
		height: 48px;
		margin-right: 20px;
		background: $bg-light;
		border-radius: 6px;
		font-size: 22px;
		align-items: center;
		justify-content: center;
		flex: 0 0 48px;
		@include m {
			margin-right: 16px; }
		&_file {
			flex: 0 0 40px; } }
	&__preview_file &__pic {
		max-width: 20px;
		max-height: 20px;
		border-radius: 0; }
	&__pic {
		border-radius: 6px; }
	&__label {
		color: $gray; }
	&__content {
		font-size: 16px;
		&_between {
			display: flex;
			justify-content: space-between; }
		.la {
			margin-right: 4px;
			color: $gray; } }
	&__stars {
		.la {
			font-size: 16px;
			color: $blue;
			&.active {
				opacity: .5;
				color: $gray; } } }
	&__corner {
		display: flex;
		position: absolute;
		top: 16px;
		right: 16px;
		min-width: 36px;
		min-height: 36px;
		justify-content: center;
		align-items: center;
		z-index: 2;
		&_left {
			left: 16px;
			right: auto; } }
	&__corner &__tag {
		min-width: 0;
		padding: 7px 12px 8px; }
	&__foot {
		display: flex;
		width: calc(100% + 32px);
		margin: 12px -16px -16px;
		border-top: 1px solid $border; }
	&__box {
		padding: 16px 16px 18px;
		justify-content: center;
		flex: 0 0 50%;
		@include m {
			padding: 14px; }
		&:first-child {
			border-right: 1px solid $border; } }
	&__progress {
		margin: 9px 0 4px; }

	&_list {
		overflow: auto; }
	&_list &__container {
		min-width: 884px;
		@include m {
			min-width: 0; } }

	&_grid &__head &__cell {
		&:last-child {
			padding-right: 0; } }
	&_grid &__body {
		display: flex;
		margin: 0 -8px -16px;
		text-align: center;
		flex-wrap: wrap;
		@include m {
			display: block;
			margin: 0; } }
	&_grid &__item {
		position: relative;
		min-width: 240px;
		margin: 0 8px 16px;
		padding: 24px 16px 16px;
		flex: 1 1 calc(25% - 16px);
		@include m {
			margin: 0 0 4px; } }
	&_grid &__item &__row {
		flex-direction: column; }
	&_grid &__item &__cell {
		width: 100%;
		padding: 12px 0; }
	&_grid &__main {
		padding: 0;
		flex-direction: column; }
	&_grid &__preview {
		width: 64px;
		height: 64px;
		margin: 0 auto 12px;
		flex: 0 0 64px;
		&_large {
			width: auto;
			height: auto;
			margin: -36px -16px 24px; } }
	&_grid &__preview_large &__pic {
		width: 100%;
		border-radius: 11px 11px 0 0; }
	&_grid &__preview_file {
		width: 52px; }
	&_grid &__members,
	&_grid &__tags {
		justify-content: center; }

	&_messages &__body {
		padding-top: 0; }

	&.no-header &__body {
		padding-top: 0;
		padding-bottom: 0; } }










