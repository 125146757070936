.panel {
	padding: 24px;
	background: white;
	border-radius: 12px;
	@include m {
		padding: 0;
		background: none; }
	&__head {
		display: flex;
		margin-bottom: 24px;
		align-items: center;
		justify-content: space-between;
		flex-wrap: wrap;
		@include m {
			display: block;
			margin-bottom: 18px; }
		&_line {
			@include m {
				display: flex; } } }
	&__head &__title {
		padding: 5px 0 4px;
		@include m {
			margin-bottom: 18px;
			padding: 0; } }
	&__head_line &__title {
		@include m {
			margin: 0; } }
	&__group {
		@include m {
			justify-content: space-between; } }
	&__text {
		color: $gray; }
	&__body {
		&_bg {
			@include m {
				padding: 24px;
				background: white;
				border-radius: 16px; } } }
	&__info &__title {
		.la {
			margin-left: 4px;
			font-size: 22px; } }
	&__chart {
		height: 280px;
		&_md {
			height: 220px; }
		&_sm {
			height: 180px; }
		&_pie {
			display: flex;
			align-items: center;
			justify-content: center; } }
	&__series {
		margin-top: 20px; }
	&__stats {
		display: flex;
		margin: 24px -24px -24px;
		border-top: 1px solid $border;
		text-align: center; }
	&__stat {
		padding: 24px 12px;
		flex: 1;
		&:not(:last-child) {
			border-right: 1px solid $border; } }
	&__map {
		margin: 0 -24px; }
	&__foot {
		display: flex;
		margin-top: 36px;
		align-items: center;
		@include m {
			margin-top: 18px; } }
	&__foot &__text {
		margin-left: 18px; } }
