.caption {
	display: inline-flex;
	font-size: 12px;
	font-weight: 700;
	color: $cl;
	text-transform: uppercase;
	align-items: center;
	justify-content: center;
	.la {
		position: relative;
		top: -1px;
		margin-right: 5px; } }
