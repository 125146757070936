.search {
	position: relative;
	&__dropdown {
		display: none;
		position: absolute;
		top: 0;
		right: 0;
		width: 444px;
		z-index: 99;
		@include m {
			left: 0;
			right: auto;
			width: calc(100vw - 32px); } }
	&__field {
		position: relative; }
	&__input {
		width: 100%;
		height: 36px;
		padding: 0 36px 0 18px;
		border-radius: 6px; }
	&__icon {
		display: flex;
		position: absolute;
		top: 0;
		right: 0;
		width: 36px;
		height: 36px;
		align-items: center;
		justify-content: center;
		.la {
			font-size: 16px; } }
	&__results {
		display: none;
		margin-top: 10px;
		background: white;
		border-radius: 6px;
		max-height: 500px;
		overflow: auto;
		&.visible {
			display: block; } }
	&__result {
		display: flex;
		padding: 20px 12px;
		color: $cl;
		align-items: center;
		&:not(:last-child) {
			border-bottom: 1px solid $border; } }
	&__cell {
		padding: 0 8px;
		&:last-child {
			margin-left: auto; } }
	&__preview {
		display: flex;
		width: 42px;
		height: 42px;
		background: $bg-light;
		border-radius: 6px;
		align-items: center;
		justify-content: center;
		i {
			font-size: 22px; } }
	&__pic {
		border-radius: 6px; }
	&__title {
		font-weight: 700; }
	&__text {
		color: $gray; }
	&__tag {
		min-width: 110px; }

	&.open &__dropdown,
	&.open &__backdrop {
		display: block; } }
