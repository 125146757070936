.help {
	background: white;
	border-radius: 12px;
	@include m {
		background: none; }
	&__head {
		min-height: 292px;
		padding: 66px 60px 34px;
		background: url(../img/bg-help.jpg) no-repeat center/cover;
		border-radius: 12px 12px 0 0;
		text-align: center;
		@include m {
			min-height: 0;
			margin: -16px -16px 24px;
			padding: 42px 20px;
			border-radius: 0; } }
	&__head &__title {
		@include m {
			font-size: 26px; } }
	&__form {
		max-width: 634px;
		margin: 0 auto; }
	&__form &__title {
		margin-bottom: 4px; }
	&__text {
		margin-bottom: 32px; }
	&__group {
		margin-top: 52px;
		justify-content: center; }
	&__body {
		padding: 60px;
		@include m {
			padding: 0; } }
	&__body &__title {
		margin-bottom: 32px;
		@include m {
			text-align: center; } }
	&__row {
		display: flex;
		@include m {
			display: block; } }
	&__col {
		flex: 1 1 auto;
		&:not(:last-child) {
			margin-right: 100px;
			@include t {
				margin-right: 40px; }
			@include m {
				margin: 0 0 24px; } } }
	&__preview {
		height: 130px;
		margin-bottom: 42px;
		@include m {
			display: none; } }
	&__pic {
		max-height: 100%; }
	&__list {
		display: flex;
		flex-direction: column;
		align-items: flex-start;
		@include m {
			align-items: center; } }
	&__link {
		margin-bottom: 20px;
		color: $gray; }

	&_sidebar &__head {
		min-height: 276px;
		margin: -28px -28px 0;
		border-radius: 0; }
	&_sidebar &__body {
		padding: 36px 0 0; } }
