.info {
	&__section {
		@include m {
			padding: 12px 16px 16px;
			background: white;
			border-radius: 6px; }
		&:not(:last-child) {
			margin-bottom: 24px;
			@include m {
				margin-bottom: 4px; } } }
	&__title {
		margin-bottom: 12px;
		font-weight: 700;
		@include m {
			margin-bottom: 12px;
			font-size: 12px;
			color: $gray; } }
	&__text {
		color: $gray;
		@include m {
			color: $cl; } }
	&__cover {
		padding: 18px 24px 24px;
		background: $bg-lighter;
		border-radius: 8px;
		@include m {
			padding: 0;
			background: none; } }
	&__stats {
		display: flex;
		justify-content: space-between; }
	&__values {
		color: $gray;
		strong {
			color: $cl; } }
	&__progress {
		margin-top: 12px; } }
