.slider {
	&__el {
		height: 6px;
		background: #eee;
		border: none;
		border-radius: 0;
		box-shadow: none; }
	&__scales {
		display: flex;
		margin-top: 22px;
		justify-content: space-between; } }

.noUi {
	&-handle {
		border: none;
		box-shadow: 0px 3px 5px rgba(0, 0, 0, 0.1);
		border-radius: 50%;
		&:before,
		&:after {
			display: none; } }
	&-horizontal &-handle {
		right: -7px;
		top: -4px;
		width: 14px;
		height: 14px; }
	&-connect {
		background: $blue; }
	&-tooltip {
		display: flex;
		height: 36px;
		min-width: 36px;
		background: #eee;
		border: none;
		align-items: center;
		justify-content: center; } }
