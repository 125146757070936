.field {
	&__label {
		position: relative;
		margin-bottom: -10px;
		font-size: 12px;
		color: $gray;
		z-index: 2; }
	&__wrap {
		position: relative; }
	&__input,
	&__select {
		display: block;
		width: 100%;
		height: 54px;
		padding-right: 24px;
		background-color: transparent;
		border: solid $border;
		border-width: 0 0 1px;
		transition: border-color .2s;
		&:focus {
			border-color: $blue; } }
	&__select {
		border-radius: 0;
		appearance: none;
		color: $gray;
		line-height: 1;
		&.active {
			color: $cl; } }
	&__icon {
		display: flex;
		position: absolute;
		top: 0;
		right: 0;
		height: 100%;
		color: $gray;
		align-items: center;
		justify-content: center;
		pointer-events: none; }

	&.active &__icon {
		color: $cl; }

	&.success &__input {
		border-color: $green; }
	&.success &__icon {
		color: $green; }

	&.error &__input {
		border-color: $red; }
	&.error &__icon {
		color: $red; } }
