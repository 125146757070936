.meta {
	display: inline-flex;
	color: $gray;
	align-items: center;
	.la {
		margin-right: 8px; } }

.meta-group {
	display: flex;
	flex-wrap: wrap;
	.meta {
		&:not(:last-child) {
			margin-right: 16px; } } }
