// font-family
@mixin font($alias, $name) {
  @font-face {
    font-family: $alias;
    src: url("fonts/" + $name + ".woff2") format("woff2"), url("fonts/" + $name + ".woff") format("woff");
    font-weight: normal;
    font-style: normal; } }
// to use:
//+font(black, cytiapro-black-webfont)

@mixin cover {
  background-repeat: no-repeat;
  background-position: 50% 50%;
  background-size: cover; }

@mixin scrollbar {
  &::-webkit-scrollbar {
    @content; } }

@mixin scrollbar-track {
  &::-webkit-scrollbar-track {
    @content; } }

@mixin scrollbar-thumb {
  &::-webkit-scrollbar-thumb {
    @content; } }

@mixin scrollbar-track-piece {
  &::-webkit-scrollbar-track-piece {
    @content; } }

//triangle
@mixin arr($width, $height, $bg, $direction) {
  width: 0px;
  height: 0px;
  border-style: solid;
  @if $direction == t {
    border-width: 0 $width / 2 + px $height + px $width / 2 + px;
    border-color: transparent transparent $bg transparent; }
  @if $direction == r {
    border-width: $height / 2 + px 0 $height / 2 + px $width + px;
    border-color: transparent transparent transparent $bg; }
  @if $direction == b {
    border-width: $height + px $width / 2 + px 0 $width / 2 + px;
    border-color: $bg transparent transparent transparent; }
  @if $direction == l {
    border-width: $height / 2 + px $width + px $height / 2 + px 0;
    border-color: transparent $bg transparent transparent; }
  @if $direction == tl {
    border-width: $height + px $width + px 0 0;
    border-color: $bg transparent transparent transparent; }
  @if $direction == tr {
    border-width: 0 $width + px $height + px 0;
    border-color: transparent $bg transparent transparent; }
  @if $direction == br {
    border-width: 0 0 $height + px $width + px;
    border-color: transparent transparent $bg transparent; }
  @if $direction == bl {
    border-width: $width + px 0 0 $height + px;
    border-color: transparent transparent transparent $bg; } }
// example
// +arr(500,50, #000, l)
// +arr(500,50, #000, bl)

@mixin rotate($var) {
  transform: rotate($var+deg); }

@mixin blur($var) {
  filter: blur($var); }
@mixin scale($var) {
  transform: scale($var); }

@mixin counter($var, $sep) {
  counter-reset: list + $var;
  > li {
    &:before {
      content: counter(list + $var) $sep;
      counter-increment: list + $var; } } }

@mixin f($name, $font-size: null, $letter-spacing: null) {
  $font-family: null;
  @if $name == "l" {
    $font-family: "light"; }
  font-family: $font-family;
  font-weight: normal;
  @if $font-size != null {
    font-size: $font-size + px; }
  @if $letter-spacing != null {
    letter-spacing: ($letter-spacing / 1000) + em; } }

