.nav {
	display: flex;
	height: 100%;
	background: white;
	border-right: 1px solid $border;
	flex-direction: column;
	@include m {
		position: fixed;
		left: 0;
		bottom: 0;
		width: 100%;
		height: auto; }
	&__list {
		padding-bottom: 10px;
		flex: 1 1 auto;
		overflow: auto;
		@include m {
			padding: 0; } }
	&__link {
		position: relative;
		display: flex;
		align-items: center;
		padding: 8px 18px;
		color: $gray;
		transition: color .2s;
		&:hover {
			color: $cl; }
		&.active {
			color: $cl;
			&:after {
				content: '';
				position: absolute;
				top: 8px;
				right: 0;
				bottom: 8px;
				width: 2px;
				background: $blue;
				border-radius: 2px; } }
		&_head {
			min-height: 76px;
			@include m {
				display: none; } }
		&_menu {
			display: none;
			@include m {
				display: flex; } }
		&_user {
			margin-top: 38px; } }
	&__link_user &__online {
		top: auto;
		right: 5px;
		bottom: 5px; }
	&__link.active &__preview,
	&__link.active &__title {
		background: $blue-light; }
	&__link.active &__preview {
		color: $blue; }
	&__preview {
		display: flex;
		position: relative;
		height: 48px;
		border-radius: 4px;
		align-items: center;
		justify-content: center;
		transition: color .2s;
		flex: 0 0 48px;
		.la {
			font-size: 22px; } }
	&__preview &__pic {
		width: 30px;
		border-radius: 5px; }
	&__online {
		top: 10px;
		bottom: auto;
		right: 8px; }
	&__title {
		display: none;
		min-height: 48px;
		padding: 5px 5px 5px 15px;
		border-radius: 0 4px 4px 0;
		font-weight: 700;
		align-items: center;
		flex: 1 1 auto; }
	&__primary {
		@include m {
			position: absolute;
			left: 0;
			bottom: 0;
			width: 100%;
			padding: 0 10px;
			background: white;
			box-shadow: 0 0 16px rgba(black,.1);
			transition: transform .4s;
			z-index: 4;
			&.hidden {
				transform: translateY(calc(100% + 16px)); } } }
	&__primary &__group {
		@include m {
			display: flex;
			justify-content: space-between; } }
	&__primary &__link {
		@include m {
			max-width: 56px;
			padding: 14px 4px;
			flex: 1;
			&.active {
				&:after {
					top: 0;
					bottom: auto;
					right: calc(50% - 24px);
					width: 48px;
					height: 2px; } } } }
	&__secondary {
		@include m {
			display: none;
			position: fixed;
			top: 0;
			left: 0;
			width: 100%;
			height: 100%;
			padding: 48px 16px 92px;
			background: $bg-light;
			overflow: auto;
			z-index: 2;
			&.visible {
				display: block; } } }
	&__secondary &__group {
		@include m {
			display: flex;
			flex-direction: column; } }
	&__secondary &__link {
		@include m {
			min-height: 64px;
			margin-bottom: 4px;
			background: white;
			border-radius: 10px;
			order: 2;
			&.active {
				&:after {
					left: 0;
					right: auto; } } } }
	&__secondary &__link_user {
		@include m {
			display: block;
			margin: 0 0 38px;
			background: none;
			flex-direction: column;
			order: 1; } }
	&__secondary &__link_user &__preview {
		@include m {
			width: 94px;
			height: 94px;
			margin: 0 auto 10px; } }
	&__secondary &__link_user &__pic {
		@include m {
			width: 100%;
			border-radius: 12px; } }
	&__secondary &__link_user &__online {
		@include m {
			width: 16px;
			height: 16px;
			right: -6px;
			bottom: -6px;
			border-color: $bg-light; } }
	&__secondary &__link_user &__title {
		@include m {
			min-height: 0;
			padding: 0;
			font-size: 26px;
			color: $cl;
			line-height: (38/26);
			justify-content: center; } }
	&__secondary &__title {
		@include m {
			display: flex; } }
	&__secondary &__preview {
		@include m {
			border-radius: 4px 0 0 4px; } }

	&.extended {
		@include ml {
			width: 242px; } }
	&.extended &__title {
		@include ml {
			display: flex; } }
	&.extended &__preview {
		@include ml {
			border-radius: 4px 0 0 4px; } } }







