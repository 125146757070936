.badge {
	display: inline-block;
	min-width: 24px;
	padding: 0 6px;
	background: $blue-light;
	border-radius: 12px;
	font-weight: 700;
	color: $blue;
	text-align: center;
	line-height: 24px;
	&.red {
		background: rgba($red,.1);
		color: $red; }
	&.green {
		background: rgba($green,.1);
		color: $green; }
	&.yellow {
		background: rgba($yellow,.1);
		color: $yellow; }
	&.purple {
		background: rgba($purple,.1);
		color: $green; } }
