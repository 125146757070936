.select2 {
	&-container {
		display: block;
		width: 100%; }
	&-container &-selection--single {
		height: 56px;
		border-color: $border;
		border-width: 0 0 1px;
		border-radius: 0; }
	&-container--default &-selection--single &-selection__rendered {
		padding: 0 24px 0 0;
		font-weight: 700;
		line-height: 56px; }
	&-container--default &-selection--single &-selection__placeholder {
		color: $gray; }
	&-container--default &-search--dropdown &-search__field {
		height: 46px;
		padding: 0 16px;
		border: none;
		border-radius: 7px; }
	&-container--default &-results__option[aria-selected=true] {
		background: $bg-light; }
	&-container--default &-results__option--highlighted[aria-selected] {
		background: $blue; }
	&-container--open &-dropdown--above {
		border: 1px solid $border; }
	&-container--default &-selection--multiple {
		border: none; }
	&-container &-selection--multiple {
		min-height: 56px;
		border-bottom: 1px solid $border;
		border-radius: 0; }
	&-container--default#{&}-container--focus &-selection--multiple {
		border: none; }
	&-container--default &-selection--multiple &-selection__rendered {
		padding: 10px 20px 0 0; }
	&-container--default &-selection--multiple &-selection__choice {
		padding: 4px 8px;
		background: $bg-light;
		border: 1px solid $border;
		border-radius: 2px;
		font-weight: 700; }
	&-container--default &-selection--multiple &-selection__choice__remove {
		margin-right: 6px; }

	&-selection {
		&__arrow {
			display: none; } }

	&-dropdown {
		border-radius: 8px;
		border-color: $border;
		box-shadow: 0px 6px 20px rgba(153, 155, 168, 0.1); }
	&-dropdown--below &-results__option {
		&:last-child {
			border-radius: 0 0 7px 7px; } }

	&-results__option {
		padding: 12px 16px;
		font-weight: 700; }

	&-search--dropdown {
		padding: 0;
		border-bottom: 1px solid $border; }

	&-search__field {
		height: 31px; }

	&-icon-option {
		display: flex;
		.la {
			margin-right: 12px; } } }






