.overview {
	&__item {
		padding: 20px 24px;
		background: $bg-lighter;
		border-radius: 10px;
		&:not(:last-child) {
			margin-bottom: 6px; } }
	&__row {
		display: flex;
		align-items: center;
		justify-content: space-between; }
	&__col {
		&:last-child {
			margin-left: 16px; } }
	&__label {
		color: $gray; }
	&__value {
		font-size: 20px;
		font-weight: 700;
		@include m {
			font-size: 18px; }
		.la {
			margin-left: 4px; } }
	&__subtitle {
		font-size: 18px;
		font-weight: 700; }
	&__preview {
		display: flex;
		width: 56px;
		height: 56px;
		border-radius: 10px;
		align-items: center;
		justify-content: center;
		.la {
			font-size: 22px; } }
	&__progress {
		margin-top: 10px; }
	&__chart {
		max-width: 120px;
		svg {
			overflow: visible; } }

	&_line {
		display: flex;
		margin: 0 -14px;
		flex-wrap: wrap;
		@include m {
			margin-bottom: 18px;
			padding-left: 12px;
			flex-wrap: nowrap;
			overflow: auto;
			&:after {
				content: '';
				flex: 0 0 12px; } } }
	&_line &__item {
		margin: 0 14px 28px;
		background: white;
		flex: 1;
		@include t {
			min-width: calc(50% - 28px); }
		@include m {
			min-width: 140px;
			margin: 0 4px;
			flex: 0 0 330px; } }

	&_compact &__item {
		@include m {
			padding: 20px 12px;
			text-align: center;
			flex: 0 0 140px; } }
	&_compact &__row {
		@include m {
			flex-direction: column-reverse; } }
	&_compact &__col {
		@include m {
			&:last-child {
				margin: 0 0 10px; } } } }
