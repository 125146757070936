.members {
	display: flex;
	margin: 0 -4px -4px 0;
	align-items: center;
	flex-wrap: wrap;
	&__item {
		width: 36px;
		margin: 0 4px 4px 0;
		flex: 0 0 36px; }
	&__pic {
		display: block;
		width: 100%;
		border-radius: 6px; }
	&__counter {
		height: 36px;
		background: $bg;
		border-radius: 6px;
		color: $gray;
		text-align: center;
		line-height: 36px; } }
