.contacts {
	&__item {
		display: flex;
		align-items: flex-start;
		&:not(:last-child) {
			margin-bottom: 16px; } }
	&__icon {
		margin-right: 12px;
		padding-top: 1px;
		color: $gray; } }
