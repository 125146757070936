.widget {
	&__head {
		margin-bottom: 16px;
		&_line {
			display: flex;
			align-items: baseline; } }
	&__text {
		color: $gray; }
	&__badge {
		margin-left: 6px; }
	&__all {
		margin-left: auto;
		font-weight: 700; } }
