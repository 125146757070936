// media queries
@mixin w {
  @media only screen and (max-width: "1579px") {
    @content; } }
@mixin l {
  @media only screen and (max-width: "1339px") {
    @content; } }
@mixin ml {
  @media only screen and (min-width: "1340px") {
    @content; } }
@mixin d {
  @media only screen and (max-width: "1259px") {
    @content; } }
@mixin md {
  @media only screen and (min-width: "1260px") {
    @content; } }
@mixin t {
  @media only screen and (max-width: "1023px") {
    @content; } }
@mixin m {
  @media only screen and (max-width: "767px") {
    @content; } }
@mixin s {
  @media only screen and (max-width: "413px") {
    @content; } }

// fonts
@mixin lato {
	font-family: 'Lato', sans-serif; }

// colors
$blue: #5E81F4;
$blue-light: #EFF2FE;
$gray: #8181A5;
$yellow: #F4BE5E;
$green: #7CE7AC;
$red: #FF808B;
$purple: #9698D6;
$turquoise: #2CE5F6;

$cl: #1C1D21;
$border: #F0F0F3;
$btn-hover: #475EAA;
$btn-outline: #ECECF2;
$bg: #F6F6F6;
$bg-light: #F5F5FA;
$bg-lighter: #FBFBFD;

