.green {
  background: rgba($green,.1);
  color: $green; }
.red {
  background: rgba($red,.1);
  color: $red; }
.blue {
  background: rgba($blue,.1);
  color: $blue; }
.yellow {
  background: rgba($yellow,.1);
  color: $yellow; }
.purple {
  background: rgba($purple,.1);
  color: $purple; }
.gray {
  background: $bg-light;
  color: $gray; }
.turquoise {
  background: rgba($turquoise,.1);
  color: $gray; }

.color {
  &-green {
    color: $green; }
  &-red {
    color: $red; }
  &-blue {
    color: $blue; }
  &-yellow {
    color: $yellow; }
  &-purple {
    color: $purple; }
  &-gray {
    color: $gray; }
  &-turquoise {
    color: $turquoise; } }

.bg {
  &-green {
    background: rgba($green,.1);
    &-raw {
      background: $green; } }
  &-red {
    background: rgba($red,.1);
    &-raw {
      background: $red; } }
  &-blue {
    background: rgba($blue,.1);
    &-raw {
      background: $blue; } }
  &-yellow {
    background: rgba($yellow,.1);
    &-raw {
      background: $yellow; } }
  &-purple {
    background: rgba($purple,.1);
    &-raw {
      background: $purple; } }
  &-gray {
    background: rgba($gray,.1);
    &-raw {
      background: $gray; } }
  &-turquoise {
    background: rgba($turquoise,.1);
    &-raw {
      background: $turquoise; } } }

.text {
  &-right {
    text-align: right; }
  &-center {
    text-align: center; }
  &-uppercase {
    text-transform: uppercase; } }

.m-auto {
  margin: auto; }
.ml-auto {
  margin-left: auto; }
.mr-auto {
  margin-right: auto; }

.align {
  &-baseline {
    align-items: baseline; } }

.desktop {
  &-hide {
    @include d {
      display: none; } }
  &-show {
    display: none;
    @include d {
      display: block; } }
  &-text-right {
    @include d {
      text-align: right; } } }

.tablet {
  &-hide {
    @include t {
      display: none; } }
  &-show {
    display: none;
    @include t {
      display: block; } }
  &-text-right {
    @include t {
      text-align: right; } } }

.mobile {
  &-hide {
    @include m {
      display: none; } }
  &-show {
    display: none;
    @include m {
      display: block; } }
  &-text-right {
    @include m {
      text-align: right; } } }

