.btn {
	display: inline-flex;
	height: 46px;
	padding: 0 28px;
	background: $blue;
	border: 1px solid $blue;
	border-radius: 8px;
	font-weight: 700;
	color: white;
	transition: all .2s;
	align-items: center;
	justify-content: center;
	vertical-align: middle;
	&:hover {
		background: $btn-hover;
		border-color: $btn-hover; }
	&[disabled] {
		background: $bg;
		border-color: $bg;
		color: $gray; }
	&_light {
		background: $blue-light;
		border-color: $blue-light;
		color: $blue;
		&:hover {
			background: darken($blue-light, 5);
			border-color: darken($blue-light, 5); }
		&[disabled] {
			background: $bg;
			border-color: $bg;
			color: $gray; } }
	&_stroke {
		background: white;
		border-color: $blue;
		color: $blue;
		&:hover {
			background: white;
			border-color: $blue;
			color: $cl; }
		&[disabled] {
			background: white;
			border-color: $btn-outline;
			color: $gray; } }
	&_icon {
		padding: 0 18px;
		.la {
			margin-right: 8px; } }
	&_only_icon {
		padding: 0 13px;
		font-size: 0;
		.la {
			font-size: 18px; } } }

.btn-group {
	display: flex;
	margin: 0 -6px -6px 0;
	flex-wrap: wrap;
	.btn {
		height: 40px;
		margin: 0 6px 6px 0;
		&.active {
			background: $blue;
			border-color: $blue;
			color: white; } } }

.btn-group_tabs {
	@include m {
		margin: 0 -3px;
		flex: 1 1 auto; }
	.btn {
		@include m {
			min-width: calc(33.33% - 6px);
			height: 36px;
			margin: 0 3px;
			padding: 0 9px;
			background: none;
			border-color: transparent;
			border-radius: 6px;
			color: $gray;
			flex: 1;
			&.active {
				background: white;
				border-color: transparent;
				color: $cl; } } }
	.la {
		@include m {
			display: none; } } }
