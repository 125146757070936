.tag {
	display: inline-flex;
	min-width: 98px;
	padding: 6px 15px 7px;
	border: 1px solid transparent;
	border-radius: 8px;
	font-weight: 700;
	justify-content: center;
	align-items: center;
	@include m {
		min-width: 0;
		padding: 6px 8px 7px; }
	&_stroke {
		background: white;
		border-color: $border; }
	.la {
		position: relative;
		top: -1px;
		margin-right: 8px;
		color: $gray; } }

.tag-group {
	display: flex;
	margin: 0 -4px -4px 0;
	flex-wrap: wrap;
	.tag {
		margin: 0 4px 4px 0; } }
