.new {
	position: relative;
	&__action {
		position: relative;
		transition: none; }
	&__dropdown {
		display: none;
		position: absolute;
		top: 46px;
		right: 0;
		width: 286px;
		background: white;
		border-radius: 6px;
		z-index: 99;
		@include m {
			position: fixed;
			top: auto;
			left: 0;
			bottom: 0;
			width: 100%;
			max-height: 100%;
			border-radius: 16px 16px 0 0;
			overflow: auto; } }
	&__item {
		display: flex;
		padding: 20px;
		color: $cl;
		align-items: center;
		&:not(:last-child) {
			border-bottom: 1px solid $border; } }
	&__icon {
		display: flex;
		height: 36px;
		margin-right: 16px;
		background: rgba($blue,.1);
		border-radius: 6px;
		color: $blue;
		align-items: center;
		justify-content: center;
		flex: 0 0 36px; }
	&__title {
		font-weight: 700; }

	&.open &__action {
		background: $blue;
		color: white;
		z-index: 100; }
	&.open &__dropdown,
	&.open &__backdrop {
		display: block; } }
