.desk {
	display: flex;
	margin: 0 -8px;
	@include d {
		display: block; }
	&__col {
		margin: 0 8px;
		padding: 24px;
		border-radius: 10px;
		border: 1px dashed $border;
		flex: 1;
		@include d {
			&:not(:last-child) {
				margin-bottom: 24px; } }
		@include m {
			padding: 0;
			border: none; } }
	&__head {
		display: flex;
		margin-bottom: 24px;
		align-items: center;
		justify-content: space-between;
		@include m {
			margin-bottom: 12px; } }
	&__title {
		font-weight: 700;
		color: $gray; }
	&__caption {
		margin-left: 16px; }
	&__list {
		@include d {
			display: flex;
			margin: 0 -8px -16px;
			flex-wrap: wrap; } }
	&__item {
		padding: 20px;
		border: 1px solid $border;
		border-radius: 12px;
		@include d {
			margin: 0 8px 16px;
			flex: 0 0 calc(50% - 16px); }
		@include t {
			flex: 0 0 calc(100% - 16px); }
		@include m {
			background: white; }
		&:not(:last-child) {
			margin-bottom: 6px;
			@include d {
				margin-bottom: 16px; }
			@include m {
				margin-bottom: 4px; } } }
	&__top {
		display: flex;
		margin-bottom: 16px;
		justify-content: space-between; }
	&__action {
		margin-left: 16px; }
	&__name {
		font-size: 16px;
		font-weight: 700; }
	&__text {
		color: $gray; }
	&__details {
		display: flex;
		margin-bottom: 10px;
		align-items: center;
		justify-content: space-between; }
	&__foot {
		display: flex;
		margin-top: 24px;
		align-items: center;
		justify-content: space-between; }
	&__tag {
		margin-right: 16px; } }
