.layout {
	margin-bottom: -28px;
	&__tabs {
		display: none;
		@include m {
			display: flex;
			margin: 0 -4px 16px; } }
	&__tabs &__action {
		margin: 0 4px;
		flex: 1; }
	&__row {
		display: flex;
		margin: 0 -14px;
		@include t {
			display: block;
			margin: 0; } }
	&__panel {
		margin: 0 14px 28px;
		flex: 1;
		@include t {
			margin: 0 0 28px; }
		@include m {
			max-width: 100%; }
		&_x2 {
			flex: 0 0 calc(66.66% - 28px); }
		&_tab {
			@include m {
				display: none;
				&.visible {
					display: block; } } } }
	&__col {
		flex: 1; }

	&_stroke &__panel {
		border: 1px solid $border;
		border-radius: 12px;
		@include m {
			border: none; } } }
