@import "datepicker-config";

/* -------------------------------------------------
    Datepicker cells
   ------------------------------------------------- */

.datepicker--cells {
  display: flex;
  flex-wrap: wrap;
}

.datepicker--cell {
  border-radius: $datepickerBorderRadius;
  box-sizing: border-box;
  cursor: pointer;
  display: flex;
  position: relative;
  align-items: center;
  justify-content: center;
  height: $datepickerDayCellSize;
  z-index: 1;

  &.-focus- {
    background: map_get($datepickerBG, hover);
  }

  &.-current- {
    color: map_get($datepickerTextColor, currentDate);

    &.-focus- {
      color: map_get($datepickerTextColor, common);
    }

    &.-in-range- {
      color: map_get($datepickerTextColor, currentDate);
    }
  }

  &.-in-range- {
    background: map_get($datepickerBG, inRange);
    color: map_get($datepickerTextColor, common);
    border-radius: 0;

    &.-focus- {
      background-color: rgba(map_get($datepickerBG, inRange), .2);
    }
  }

  &.-disabled- {
    cursor: default;
    color: map_get($datepickerTextColor, disabled);

    &.-focus- {
      color: map_get($datepickerTextColor, disabled);
    }

    &.-in-range- {
      color: darken(map_get($datepickerTextColor, disabled), 5);
    }

    &.-current- {
      &.-focus- {
        color: map_get($datepickerTextColor, disabled);
      }
    }
  }

  &.-range-from- {
    border: 1px solid rgba(map_get($datepickerBG, selected), .5);
    background-color: map_get($datepickerBG, inRange);
    border-radius: $datepickerBorderRadius 0 0 $datepickerBorderRadius;
  }
  &.-range-to- {
    border: 1px solid rgba(map_get($datepickerBG, selected), .5);
    background-color: map_get($datepickerBG, inRange);
    border-radius:  0 $datepickerBorderRadius $datepickerBorderRadius 0;
  }

  &.-range-from-.-range-to- {
    border-radius: $datepickerBorderRadius;

  }

  &.-selected- {
    color: #fff;
    border: none;
    background: map_get($datepickerBG, selected);

    &.-current- {
      color: #fff;
      background: map_get($datepickerBG, selected);
    }

    &.-focus- {
      background: map_get($datepickerBG, selectedHover);
    }
  }

  &:empty {
    cursor: default;
  }
}

//  Day names
// -------------------------------------------------

.datepicker--days-names {
  display: flex;
  flex-wrap: wrap;
  margin: 8px 0 3px;
}

.datepicker--day-name {
  color: map_get($datepickerTextColor, dayNames);
  display: flex;
  align-items: center;
  justify-content: center;
  flex: 1;
  text-align: center;
  text-transform: uppercase;
  font-size: .8em;
}

//  Day cell
// -------------------------------------------------

.datepicker--cell-day {
  width: (100/7)#{'%'};

  &.-other-month- {
    @extend %otherMonth;
  }
}


//  Months
// -------------------------------------------------

.datepicker--months {}

.datepicker--cells-months {
  height: $datepickerMinBodyHeight;
}

//  Month cell
// -------------------------

.datepicker--cell-month {
  width: 33.33%;
  height: 25%;
}

//  Years
// -------------------------------------------------

.datepicker--years {
  height: $datepickerMinBodyHeight;
}

.datepicker--cells-years {
  height: $datepickerMinBodyHeight;
}
//  Year cell
// -------------------------

.datepicker--cell-year {
  width: 100% / $datepickerYearsPerRow;
  height: 33.33%;

  &.-other-decade- {
    @extend %otherMonth;
  }
}