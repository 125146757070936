.subscriptions {
	&__item {
		display: flex;
		padding: 24px 0;
		color: $gray;
		align-items: center;
		justify-content: space-between;
		&:not(:last-child) {
			border-bottom: 1px solid $border; } }
	&__cell {
		&:first-child {
			margin-right: 16px; } }
	&__title {
		font-weight: 700;
		transition: color .2s;
		&.active {
			color: $cl; } } }
