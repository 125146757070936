.file {
	position: relative;
	&__container {
		display: flex;
		width: 50%;
		min-height: calc(100vh - 220px);
		margin-right: 28px;
		padding: 64px;
		border: 1px solid $border;
		border-radius: 12px;
		text-align: center;
		flex-direction: column;
		align-items: center;
		justify-content: flex-end;
		@include t {
			width: auto;
			min-height: 0;
			margin-bottom: 32px;
			padding: 24px; }
		@include m {
			margin: -16px -16px 16px;
			background: white;
			border: none;
			border-radius: 0; } }
	&__preview {
		display: flex;
		width: 72px;
		height: 82px;
		margin-bottom: 16px;
		border-radius: 8px;
		align-items: center;
		justify-content: center; }
	&__title {
		font-size: 16px;
		font-weight: 700; }
	&__text {
		margin-bottom: auto;
		color: $gray; }
	&__members {
		margin: 58px 0;
		@include m {
			margin: 24px 0; } }
	&__chat {
		position: absolute;
		top: 0;
		right: 0;
		width: calc(50% - 28px);
		height: 100%;
		@include t {
			position: static;
			width: 100%; } } }
