body {
  @include lato;
  font-size: 14px;
  color: $cl;
  line-height: 1.5;
  &.no-scroll {
    overflow: hidden; } }
button,
input,
textarea,
select {
  @include lato;
  font-size: 14px;
  color: $cl;
  font-weight: 700; }
button {
  background: transparent;
  line-height: 1; }
input,
textarea {
  &::placeholder {
    color: $gray; } }
textarea {
  display: block;
  width: 100%;
  height: 72px;
  background: none;
  border: none;
  resize: none; }
a {
  color: $blue;
  text-decoration: none; }
img {
  display: block;
  max-width: 100%; }

.la {
  font-size: 18px; }

// +scrollbar
//   width: 4px
//   height: 4px
// +scrollbar-track
//   background: $bg
// +scrollbar-thumb
//   background: rgba($blue,.5)










