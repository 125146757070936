.tickets {
	&__item {
		margin-bottom: 8px;
		padding: 20px 24px;
		border: 1px solid $border;
		border-radius: 12px;
		@include m {
			padding: 20px 14px;
			background: white;
			border: none; } }
	&__head,
	&__foot {
		display: flex;
		align-items: center; }
	&__head {
		margin-bottom: 20px; }
	&__preview {
		display: flex;
		height: 36px;
		margin-right: 16px;
		border-radius: 8px;
		align-items: center;
		justify-content: center;
		flex: 0 0 36px; }
	&__number {
		margin-right: auto;
		font-weight: 700; }
	&__time {
		font-size: 12px;
		font-weight: 700;
		color: $gray;
		text-transform: uppercase; }
	&__action {
		margin-left: 16px; }
	&__title {
		margin-bottom: 2px; }
	&__content {
		color: $gray; }
	&__foot {
		margin-top: 20px; }
	&__user {
		margin-right: auto; }
	&__load {
		margin-top: 24px;
		text-align: center; } }
