.enter {
	display: flex;
	align-items: center;
	&__text {
		margin-right: 12px;
		color: $gray; }
	&__socials {
		display: flex; }
	&__btn {
		margin-right: 6px;
		background: white;
		border-color: $border;
		color: $gray;
		&:hover {
			background: white;
			border-color: $blue;
			color: $blue; } } }
