.updates {
	&__title {
		margin-bottom: 16px; }
	&__item {
		display: flex;
		padding: 16px;
		background: $bg-lighter;
		border-radius: 10px;
		align-items: center;
		&:not(:last-child) {
			margin-bottom: 8px; } }
	&__icon {
		display: flex;
		height: 38px;
		margin-right: 14px;
		background: $blue-light;
		border-radius: 8px;
		color: $blue;
		align-items: center;
		justify-content: center;
		flex: 0 0 38px; }
	&__name {
		margin-right: 16px;
		font-weight: 700; }
	&__value {
		margin-left: auto;
		font-size: 12px;
		font-weight: 700;
		color: $gray; } }
