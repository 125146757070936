.settings {
	display: flex;
	margin: 0 -16px;
	@include m {
		padding: 0 16px;
		flex-direction: column-reverse; }
	&__container,
	&__sidebar {
		padding: 0 16px;
		flex: 1; }
	&__container {
		display: flex;
		flex-direction: column;
		flex: 2;
		@include t {
			flex: 1.5; }
		@include m {
			padding: 16px;
			background: white;
			border-radius: 6px; } }
	&__inner {
		margin-bottom: 40px;
		@include m {
			margin-bottom: 8px; } }
	&__foot {
		margin-top: auto; }
	&__btns {
		display: flex;
		@include m {
			flex-direction: column; } }
	&__btn {
		margin-right: 6px;
		@include m {
			margin: 16px 0 0; } }
	&__sidebar {
		@include m {
			padding: 0; } }

	&_inside {
		@include m {
			margin-top: 32px; } }

	&_tickets &__container {
		@include m {
			padding: 0;
			background: none; } } }
