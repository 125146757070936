.table {
	thead {
		text-align: left; }
	th,
	td {
		@include m {
			padding: 10px;
			&:first-child {
				padding-left: 16px; }
			&:last-child {
				padding-right: 16px; } } }
	th {
		padding: 10px 14px;
		background: $bg-light;
		color: $gray;
		&:first-child {
			border-radius: 6px 0 0 6px; }
		&:last-child {
			border-radius: 0 6px 6px 0; } }
	td {
		padding: 8px 14px; }
	tbody tr:first-child td {
		padding-top: 16px; }
	&__total {
		font-size: 16px;
		@include m {
			font-size: 14px; } } }
