.events {
	&__title {
		margin-bottom: 16px; }
	&__item {
		position: relative;
		padding: 16px 44px 16px 20px;
		background: $bg-lighter;
		border-radius: 10px;
		&:not(:last-child) {
			margin-bottom: 8px; } }
	&__head {
		display: flex;
		margin-bottom: 4px;
		font-weight: 700;
		align-items: center; }
	&__circle {
		width: 8px;
		height: 8px;
		margin-right: 8px;
		background: $blue;
		border-radius: 50%;
		&.red {
			background: $red; }
		&.green {
			background: $green; }
		&.yellow {
			background: $yellow; }
		&.purple {
			background: $purple; } }
	&__time {
		color: $blue; }
	&__name {
		font-weight: 700; }
	&__text {
		color: $gray; }
	&__action {
		position: absolute;
		top: 16px;
		right: 14px;
		color: $gray;
		transition: color .2s;
		&:hover {
			color: $cl; }
		.la {
			font-size: 16px; } } }
