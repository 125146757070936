.title {
	font-size: 18px;
	font-weight: 700;
	&_xl {
		font-size: 32px;
		line-height: (42/32); }
	&_lg {
		font-size: 26px;
		line-height: (38/26); }
	&_md {
		font-size: 20px;
		line-height: 1.6; }
	&_sm {
		font-size: 16px; }

	&_md#{&}_input {
		width: 100%;
		height: 32px; } }
