.datepicker {
	width: 360px;
	border: none;
	box-shadow: 0px 6px 20px rgba(153, 155, 168, 0.1);
	border-radius: 8px;
	@include lato;
	font-size: 14px;
	@include m {
		width: 280px; }
	&--pointer {
		display: none; }
	&--nav {
		min-height: 52px;
		background: $bg-light;
		border: none;
		border-radius: 8px 8px 0 0;
		@include m {
			min-height: 38px; }
		&-action {
			width: 52px;
			@include m {
				width: 38px; } }
		&-title {
			font-size: 16px;
			font-weight: 700; } }
	&--cell {
		&-day {
			min-height: 50px;
			@include m {
				min-height: 38px; } } }
	&--day {
		&-name {
			min-height: 50px;
			color: $gray;
			text-transform: none;
			@include m {
				min-height: 38px; } } } }
