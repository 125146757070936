.items {
	&__item {
		display: flex;
		padding: 16px 20px;
		background: $bg-lighter;
		border-radius: 10px;
		color: $cl;
		align-items: flex-start;
		transition: background .2s;
		&:not(:last-child) {
			margin-bottom: 8px; }
		&:hover {
			background: $bg-lighter; }
		&.active {
			background: rgba($blue,.1); } }
	&__fav {
		margin: 8px 12px 0 0; }
	&__preview {
		display: flex;
		position: relative;
		height: 38px;
		margin: 4px 20px 4px 0;
		border-radius: 8px;
		align-items: center;
		justify-content: center;
		flex: 0 0 38px; }
	&__pic {
		border-radius: 8px; }
	&__wrap {
		flex: 1 1 auto; }
	&__details {
		display: flex;
		margin-bottom: 2px;
		font-weight: 700;
		justify-content: space-between; }
	&__time {
		margin-left: 16px;
		color: $gray; }
	&__content {
		padding-right: 30px;
		color: $gray; }

	&_stroke &__item {
		background: none;
		border: 1px solid $border;
		@include m {
			background: $bg-lighter;
			border: none; } } }
