.invoice {
	&__container {
		padding-right: 427px;
		@include t {
			padding: 0; } }
	&__head {
		display: flex;
		margin-bottom: 30px;
		align-items: center;
		@include m {
			margin: 0;
			padding: 16px;
			background: white; } }
	&__preview {
		display: flex;
		height: 48px;
		margin-right: 14px;
		border-radius: 6px;
		align-items: center;
		justify-content: center;
		flex: 0 0 48px; }
	&__about {
		margin-right: 24px; }
	&__tag {
		margin-left: auto; }
	&__inner {
		@include m {
			padding: 16px; } }
	&__inner &__title {
		margin-bottom: 16px; }
	&__details {
		margin-bottom: 22px;
		padding-bottom: 28px;
		border-bottom: 1px solid $border;
		@include m {
			margin-bottom: 16px;
			padding: 0; } }
	&__row {
		display: flex;
		&_head {
			font-weight: 700;
			color: $gray;
			@include m {
				margin-bottom: 16px;
				font-weight: 400; } } }
	&__row_body &__col {
		@include m {
			padding: 16px;
			background: white;
			border-radius: 6px; } }
	&__col {
		flex: 1;
		&:not(:last-child) {
			margin-right: 16px; }
		&:first-child {
			text-align: left; }
		&:last-child {
			text-align: right; } }
	&__company {
		padding: 20px;
		font-weight: 700;
		color: $gray;
		@include m {
			padding: 8px; } }
	&__table {
		@include m {
			background: white;
			border-radius: 6px;
			th {
				background: none; } } }
	&__chat {
		@include t {
			display: none; } } }

